<!--Módulo de Gestión de Avisos -->

<!-- Avisos Expendientes Detalle

funescom -> expediente -> avisos

fnt:        num, get, set
tip:         *get      1=avisos incidencias,2=recep.documentos
std:        *get      0,1 Pendientes, 2=Vistos
id:           *set       Id del aviso
txt:         *set       Texto respuesta observaciones
env:       *set       Envio Respuesta 0=no,1=email,2=sms
ema       *set       Email para envio
sms        *set       Telefono para sms -->

<template>
  <div class="avisosExp_detalle" v-if="schema" style="margin-left:10px">  
    <div class="cab">DATOS</div> 
    <v-sheet :elevation="4">
      <div class="contenedor">

        <div>          
          <btraextra
            :btra_permisos="btra_permisos"
            :btra_particular="Entorno.btra"                  
            btra_modulo="standard"
            @onEvent="event_btraExtra">
          </btraextra>
        </div>
        
        <!---->
        <div class="columna" style="padding-top:10px">
          <div class="conflex">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.control.value"
              :label="schema.ctrls.control.label"
              readonly>              
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.fallename.value"
              :label="schema.ctrls.fallename.label"
              readonly>              
            </v-text-field>
          </div>   

          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.obs.value"
            :label="schema.ctrls.obs.label"
            rows="6"
            no-resize
            readonly>              
          </v-textarea>

          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.txt.value"
            :label="schema.ctrls.txt.label"
            rows="6"
            :readonly="inc_visto"
            no-resize>                  
          </v-textarea>

          <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.email.value"
              :label="schema.ctrls.email.label"
              :readonly="inc_visto"
          ></v-text-field>

          <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.movil.value"
              :label="schema.ctrls.movil.label"
              :readonly="inc_visto"
          ></v-text-field>  

        </div>
      </div> 
    </v-sheet> 

    <!-- Componentes diámicos -->
    <v-dialog
      v-model="modal"
      content-class="modal"
      persistent>
        <div class="centrado">
          <component                 
            :is="componente_dinamico"
            :padre="stName"
            accion="0"
            :accionRow="record"
            :disparo="disparo"
            componenteTipo="FC"
            @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
          </component>           
        </div>
    </v-dialog> 
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import store_finder from '@/stores/modulos/store_finder.js';
  import plugs from "@/common/general_plugs";
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const exp_M = () => plugs.groute("exp_M.vue", "comp");
  
  export default {
    mixins: [mixinCtrls],
    components: { btraextra, exp_M },
    props: {
      padre: { type:String, default: '' }, 
      accionRow: { type:Object, default: ()=> {}},
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            control: { f:'control', label:'Control', value:'' },
            fallename: { f:'fallename', label:'Nombre', value:'' },
            apd_cod: { f:'apd_cod', label:'Apd Cod.', value:'' },
            apd: { f:'apd', label:'APD', value:'' },
            estado: { f:'estado', label:'Estado', value:'' },          
            obs: { f:'obs', label:'Estado', value:'' },
            txt: { f:'visto_obs', label:'Respuesta / Observaciones', value:'' },
            email: { f:'email', label:'Email', value:'' },
            movil: { f:'movil', label:'Móvil', value:'' },            
            visto_fh: { f:'visto_fh', label:'Fecha', value:'', filtro:'fecha' }                       
          }
        },
        api:'',
        stName:'stMavisosExp_detalle',
        dialog:true,
        btra_permisos:{ extra:{guardar:1, envio_email:1, envio_sms:1 }},
        record: {},
        itemsEstados: [],

        componente_dinamico:null,
        modal:false,
        disparo:false,
      };
    },

    computed: {
      inc_visto() { console.log("inc_visto:",this.accionRow);
        //return !this.accionRow.std;
        return this.accionRow.visto>'';
      },
    },


    created() {
      this.ini_data();  
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        if (!this.$store.state[this.stName]) this.$store.registerModule(this.stName, store_finder); 
        this.$store.commit('data2State', { modulo:this.stName, prop:'name', value:this.stName });

        // configuro Entorno        
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));        
        this.Entorno.btra.footer.extra = [
          { accion: "expedientes", icono: "mdi-alpha-e-circle-outline", title:'Ver/Editar Expediente' },
          { accion:'visto', icono: "mdi-check", title:'Visto/OK' },
          { accion:'envio_email', icono: "mdi-mail", title:'Enviar Email' },
          { accion:'envio_sms', icono: "mdi-phone", title:'Enviar SMS' },
        ];     
        
        // configuro botonera
        this.botoneras_set();
      },


      //
      async ini_component() {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'avisos',
          fnt:'get',
          tip:this.schema.ctrls.estado.value,
          std:0
        }};
      
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args }); 
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },


      //
      event_btraExtra(evt) {
        this[evt.accion](evt);
      },


      // configuro estado botones
      botoneras_set() {        
        this.btnSet("expedientes", { disabled:!Object.keys(this.accionRow).length? true : false });
        this.btnSet("visto", { disabled:!Object.keys(this.accionRow).length||this.accionRow.visto!=""? true : false });
        this.btnSet("envio_email", { disabled:!Object.keys(this.accionRow).length||this.accionRow.visto!=""? true : false });
        this.btnSet("envio_sms", { disabled:!Object.keys(this.accionRow).length||this.accionRow.visto!=""? true : false });
      },


      // muestro ventana expedientes
      expedientes() {
        if (!Object.keys(this.accionRow).length) {
          this.$root.$alert.open('Seleccione un Aviso', 'info');
          return;
        }

        //console.log('avisos.expedientes',this.$route,this.$router);        
        if (this.$router)
          if (this.$route.path=='/expedientes') {          
          //let pathname=window.location.pathname.split('/');        
          //if (pathname[pathname.length - 1]== 'expedientes') {          
            this.$router.push({ path:'/' });
        }            
        
        this.record= { id:this.accionRow.exp_id }
        this.componente_dinamico= "exp_M";
        this.disparo= !this.disparo;
        this.modal= true;

      },


      visto(env=0) { 
        if (env && !this.schema.ctrls.txt.value) return this.$root.$alert.open('Debe introducir Texto de Respuesta '+env, 'info');
        if (env==1 && !this.schema.ctrls.email.value) return this.$root.$alert.open('Debe introducir Correo Electrónico', 'info');
        if (env==2 && !this.schema.ctrls.movil.value) return this.$root.$alert.open('Debe introducir Teléfono Movil', 'info');        
      
        let msg='<b><u>VISTO / OK Incidencias</u></b><br> Aceptamos / VISTO la indidencia ?<br>' + 
          (env==1 ? '<br>Se envia Correo Electronico con la respuesta a <b>' + this.schema.ctrls.email.value + '</b><br>' :'') +
          (env==2 ? '<br>Se envia SMS con la respuesta al Teléfono <b>' + this.schema.ctrls.movil.value + '</b><br>' :'');

        this.$root.$alert.open(msg , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r== 0) this.visto_continue(env) }
        );
      },

      //
      async visto_continue(env) {
        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion: 'avisos',
          fnt: 'set',
          tip: 1,
          env: env,
          id:  this.accionRow.id,
          txt: this.schema.ctrls.txt.value,
          ema: this.schema.ctrls.email.value,
          mov: this.schema.ctrls.movil.value,

        }};
     
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });    
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // limpio schema
        this.record2ctrl({}, this.schema.ctrls, true);

        // limpio selected
        this.$emit('borrar_selected');

        // actualizo records en el store
        this.$store.commit(this.padre + '/update_records', apiResult.r[0]);
        this.$store.commit(this.padre + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },

      async envio_email() {
        await this.visto(1);
      },

      async envio_sms() {
        await this.visto(2);
      },


      // 
      cerrar_dinamico() {
        this.modal= false;
        this.componente_dinamico= null;
      }

    },


    watch: {
      accionRow() {
        this.record2ctrl(this.accionRow, this.schema.ctrls);
        this.botoneras_set();
      }
    }
  };
</script>
